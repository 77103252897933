import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pawel/Projekty/wyspiewana-tradycja/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dane kontaktowe`}</h1>
    <p><strong parentName="p">{`Gminny Ośrodek Kultury gminy Psary`}</strong>{`
ul. Zwycięstwa 2
42-575 Gródków`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "http://gok.psary.pl"
        }}>{`gok.psary.pl`}</a></strong></p>
    <p>{`tel: 32 267 22 59
sekretariat@gok.psary.pl`}</p>
    <div className="d-flex align-items-center">
  <img className="ml-n2 mr-4" alt="logo Gminny Ośrodek Kultury Gminy Psary" src="../logo-gok-psary.png" style={{
        height: 200
      }} />
  <img alt="logo Gmina Psary. Tu żyje się wygodniej" src="../logo-gmina-psary.png" style={{
        height: 90
      }} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      